import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _04bcc835 = () => interopDefault(import('..\\pages\\adcetris-landing.vue' /* webpackChunkName: "pages_adcetris-landing" */))
const _19e8be0c = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _2fc8f3f3 = () => interopDefault(import('..\\pages\\have-a-card.vue' /* webpackChunkName: "pages_have-a-card" */))
const _1d1abcd0 = () => interopDefault(import('..\\pages\\re-enroll\\index.vue' /* webpackChunkName: "pages_re-enroll_index" */))
const _5258950a = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _21bfbd04 = () => interopDefault(import('..\\pages\\selection.vue' /* webpackChunkName: "pages_selection" */))
const _6c16e795 = () => interopDefault(import('..\\pages\\tivdak-landing.vue' /* webpackChunkName: "pages_tivdak-landing" */))
const _72426a85 = () => interopDefault(import('..\\pages\\tukysa-landing.vue' /* webpackChunkName: "pages_tukysa-landing" */))
const _476e0a7c = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _b5d297ca = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _b5b668c8 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _b57e0ac4 = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _b561dbc2 = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _4c062148 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _510db3d8 = () => interopDefault(import('..\\pages\\need-a-card\\eligibility.vue' /* webpackChunkName: "pages_need-a-card_eligibility" */))
const _570d87a2 = () => interopDefault(import('..\\pages\\need-a-card\\patient-information.vue' /* webpackChunkName: "pages_need-a-card_patient-information" */))
const _09389315 = () => interopDefault(import('..\\pages\\need-a-card\\provider-information.vue' /* webpackChunkName: "pages_need-a-card_provider-information" */))
const _18fb76ce = () => interopDefault(import('..\\pages\\need-a-card\\success.vue' /* webpackChunkName: "pages_need-a-card_success" */))
const _c4e31d34 = () => interopDefault(import('..\\pages\\patient\\patientSearch.vue' /* webpackChunkName: "pages_patient_patientSearch" */))
const _54623d8b = () => interopDefault(import('..\\pages\\re-enroll\\eligibility.vue' /* webpackChunkName: "pages_re-enroll_eligibility" */))
const _fffc2e14 = () => interopDefault(import('..\\pages\\re-enroll\\enrollment.vue' /* webpackChunkName: "pages_re-enroll_enrollment" */))
const _c8759d40 = () => interopDefault(import('..\\pages\\re-enroll\\member-page.vue' /* webpackChunkName: "pages_re-enroll_member-page" */))
const _e1e33a3c = () => interopDefault(import('..\\pages\\re-enroll\\patient-information.vue' /* webpackChunkName: "pages_re-enroll_patient-information" */))
const _bd6f7a7c = () => interopDefault(import('..\\pages\\re-enroll\\provider-information.vue' /* webpackChunkName: "pages_re-enroll_provider-information" */))
const _36b25bfe = () => interopDefault(import('..\\pages\\re-enroll\\success.vue' /* webpackChunkName: "pages_re-enroll_success" */))
const _a174fa78 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/adcetris-landing",
    component: _04bcc835,
    name: "adcetris-landing"
  }, {
    path: "/error",
    component: _19e8be0c,
    name: "error"
  }, {
    path: "/have-a-card",
    component: _2fc8f3f3,
    name: "have-a-card"
  }, {
    path: "/re-enroll",
    component: _1d1abcd0,
    name: "re-enroll"
  }, {
    path: "/rejection",
    component: _5258950a,
    name: "rejection"
  }, {
    path: "/selection",
    component: _21bfbd04,
    name: "selection"
  }, {
    path: "/tivdak-landing",
    component: _6c16e795,
    name: "tivdak-landing"
  }, {
    path: "/tukysa-landing",
    component: _72426a85,
    name: "tukysa-landing"
  }, {
    path: "/unlock",
    component: _476e0a7c,
    name: "unlock"
  }, {
    path: "/errors/400",
    component: _b5d297ca,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _b5b668c8,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _b57e0ac4,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _b561dbc2,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _4c062148,
    name: "errors-500"
  }, {
    path: "/need-a-card/eligibility",
    component: _510db3d8,
    name: "need-a-card-eligibility"
  }, {
    path: "/need-a-card/patient-information",
    component: _570d87a2,
    name: "need-a-card-patient-information"
  }, {
    path: "/need-a-card/provider-information",
    component: _09389315,
    name: "need-a-card-provider-information"
  }, {
    path: "/need-a-card/success",
    component: _18fb76ce,
    name: "need-a-card-success"
  }, {
    path: "/patient/patientSearch",
    component: _c4e31d34,
    name: "patient-patientSearch"
  }, {
    path: "/re-enroll/eligibility",
    component: _54623d8b,
    name: "re-enroll-eligibility"
  }, {
    path: "/re-enroll/enrollment",
    component: _fffc2e14,
    name: "re-enroll-enrollment"
  }, {
    path: "/re-enroll/member-page",
    component: _c8759d40,
    name: "re-enroll-member-page"
  }, {
    path: "/re-enroll/patient-information",
    component: _e1e33a3c,
    name: "re-enroll-patient-information"
  }, {
    path: "/re-enroll/provider-information",
    component: _bd6f7a7c,
    name: "re-enroll-provider-information"
  }, {
    path: "/re-enroll/success",
    component: _36b25bfe,
    name: "re-enroll-success"
  }, {
    path: "/",
    component: _a174fa78,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
